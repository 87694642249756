@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom large box class */
@layer components {
  .primaryBG {
    @apply bg-primary
  }
  .secondaryBG {
    @apply bg-secondary
  }

  .box {
    @apply border border-gray-300 shadow-sm rounded overflow-hidden bg-white;
  }

  /* Custom box header class */
  .box-header {
    @apply bg-secondary p-1.5 flex justify-between items-center;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow: hidden;
  }

  .box-header-text {
    @apply text-base text-white mb-0;
  }
  
  .box-header-button {
    @apply rounded bg-primary border-none px-2 text-white cursor-pointer transition duration-300 ease-in-out outline-none;
  }
  

  

  .generalButton {
    @apply rounded bg-primary border-none px-4 py-2 text-white cursor-pointer transition duration-300 ease-in-out;
  }
}